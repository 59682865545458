import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import Touch from 'vuetify/lib/directives/touch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fill-height":"","fluid":""}},[_c(VRow,{attrs:{"dense":"","align":"center","justify":"center"}},[_c(VCol,[_c('SearchBox',{staticClass:"ma-2",on:{"plusClicked":_vm.newPlanning},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VRow,{attrs:{"dense":"","align":"center","justify":"center"}},[_c(VCol,[_c(VBtn,{attrs:{"color":"primary","fixed":"","bottom":"","right":"","fab":""},on:{"click":_vm.newPlanning}},[_c(VIcon,[_vm._v("mdi-plus")])],1),_c(VToolbar,{attrs:{"flat":"","dense":""}},[(_vm.$refs.calendar)?_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$helpers.string.capitalize(_vm.$refs.calendar.title)))]):_vm._e(),_c(VSpacer),(!_vm.$vuetify.breakpoint.mobile)?_c(VChipGroup,{attrs:{"color":"primary"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c(VChip,{attrs:{"value":"day"}},[_vm._v(_vm._s(_vm.$helpers.string.capitalizeI18N("day")))]),_c(VChip,{attrs:{"value":"week"}},[_vm._v(_vm._s(_vm.$helpers.string.capitalizeI18N("week")))]),_c(VChip,{attrs:{"value":"month"}},[_vm._v(_vm._s(_vm.$helpers.string.capitalizeI18N("month")))])],1):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","icon":"","color":"primary"},on:{"click":_vm.prev}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VBtn,{staticClass:"ml-2",attrs:{"outlined":"","icon":"","color":"primary"},on:{"click":_vm.next}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)],1)],1),(_vm.$vuetify.breakpoint.mobile)?_c(VRow,{attrs:{"dense":"","align":"end","justify":"end"}},[_c(VCol,[_c(VToolbar,{attrs:{"flat":"","dense":""}},[_c(VSpacer),(_vm.$vuetify.breakpoint.mobile)?_c(VSelect,{staticStyle:{"max-width":"120px"},attrs:{"items":['day', 'week', 'month'],"dense":"","solo":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$helpers.string.capitalizeI18N(item))+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$helpers.string.capitalizeI18N(item))+" ")])]}}],null,false,1053632732),model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}):_vm._e()],1)],1)],1):_vm._e(),_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,[_c(VSheet,{attrs:{"height":"600"}},[_c(VCalendar,{directives:[{def: Touch,name:"touch",rawName:"v-touch",value:({
            left: function () { return _vm.swipe('left'); },
            right: function () { return _vm.swipe('right'); },
          }),expression:"{\n            left: () => swipe('left'),\n            right: () => swipe('right'),\n          }"}],ref:"calendar",attrs:{"color":"primary","events":_vm.getEvents(),"event-color":_vm.getEventColor,"type":_vm.type,"weekdays":_vm.weekdays,"locale":_vm.$vuetify.lang.current},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"change":_vm.retrieveEvents},scopedSlots:_vm._u([{key:"event",fn:function(ref){
          var event = ref.event;
return [_c(VContainer,{staticClass:"ma-0 pa-0"},[_c(VRow,{attrs:{"no-gutters":"","align":"center"}},[(event.icon)?_c(VCol,{staticClass:"shrink ml-1"},[_c(VIcon,{staticClass:"mb-1",attrs:{"color":"white","small":""}},[_vm._v(_vm._s(event.icon))])],1):_vm._e(),_c(VCol,{staticClass:"shrink ml-1"},[_vm._v(" "+_vm._s(_vm.$helpers.date.getTimeStringFromDate(event.start))+" ")]),_c(VCol,{staticClass:"shrink font-weight-bold ml-1"},[_vm._v(" "+_vm._s(event.name)+" ")]),_c(VSpacer),(event.planning.lesson)?_c(VCol,{staticClass:"text-align-right shrink mr-1"},[_vm._v(" "+_vm._s(_vm.getUsedUnits(event.planning))),(_vm.maximumUnit(event.planning.lesson) != '~')?_c('span',[_vm._v("/"+_vm._s(_vm.maximumUnit(event.planning.lesson)))]):_vm._e()]):_vm._e()],1)],1)]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c(VMenu,{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c(VCard,{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c(VToolbar,{attrs:{"color":_vm.selectedEvent.color,"dark":""}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.editPlanning(_vm.selectedEvent)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c(VToolbarTitle,{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.name)}}),_c(VSpacer),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-heart")])],1),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)],1),_c(VCardText,[_c('span',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.details)}})]),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.selectedOpen = false}}},[_vm._v("Cancel")])],1)],1)],1)],1),_c('PlanningEditionPopup',{ref:"planningEdition",on:{"onSaveClicked":_vm.saveClicked,"onCloseClicked":_vm.retrieveAll}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }