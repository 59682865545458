<template>
  <v-container fill-height fluid>

    <!-- <v-row dense align="center" justify="center">
      <v-col cols="12" class="text-align-center">
        Remplir mon planning avec le cours ..
      </v-col>
    </v-row> -->

    <v-row dense align="center" justify="center">
      <v-col>
        <SearchBox v-model="search" @plusClicked="newPlanning" class="ma-2" />
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col>
        <v-btn color="primary" @click="newPlanning" fixed bottom right fab>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-toolbar flat dense>
          <v-toolbar-title v-if="$refs.calendar">{{
            $helpers.string.capitalize($refs.calendar.title)
          }}</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-chip-group
            v-if="!$vuetify.breakpoint.mobile"
            v-model="type"
            color="primary"
          >
            <v-chip value="day">{{ $helpers.string.capitalizeI18N("day") }}</v-chip>
            <v-chip value="week">{{ $helpers.string.capitalizeI18N("week") }}</v-chip>
            <v-chip value="month">{{ $helpers.string.capitalizeI18N("month") }}</v-chip>
          </v-chip-group>

          <v-spacer />
          <v-btn outlined icon color="primary" @click="prev">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn outlined icon color="primary" @click="next" class="ml-2">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-toolbar>
      </v-col>
    </v-row>

    <v-row dense v-if="$vuetify.breakpoint.mobile" align="end" justify="end">
      <v-col>
        <v-toolbar flat dense>
          <v-spacer />
          <v-select
            v-if="$vuetify.breakpoint.mobile"
            v-model="type"
            :items="['day', 'week', 'month']"
            dense
            solo
            hide-details
            style="max-width: 120px"
          >
            <template v-slot:item="{ item }">
              <span>{{ $helpers.string.capitalizeI18N(item) }} </span>
            </template>
            <template v-slot:selection="{ item }">
              <span>{{ $helpers.string.capitalizeI18N(item) }} </span>
            </template>
          </v-select>
        </v-toolbar>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="getEvents()"
            :event-color="getEventColor"
            :type="type"
            :weekdays="weekdays"
            :locale="$vuetify.lang.current"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="retrieveEvents"
            v-touch="{
              left: () => swipe('left'),
              right: () => swipe('right'),
            }"
          >
            <template v-slot:event="{ event }">
              <v-container class="ma-0 pa-0">
                <v-row no-gutters align="center">
                  <v-col v-if="event.icon" class="shrink ml-1">
                    <v-icon color="white" small class="mb-1">{{
                      event.icon
                    }}</v-icon>
                  </v-col>
                  <v-col class="shrink ml-1">
                    {{ $helpers.date.getTimeStringFromDate(event.start)
                    }}
                    <!-- <v-icon color="white" class="mb-1" small
                      >mdi-chevron-right</v-icon
                    >{{ $helpers.date.getTimeStringFromDate(event.end) }} -->
                  </v-col>
                  <v-col class="shrink font-weight-bold ml-1">
                    {{ event.name }}
                  </v-col>
                  <v-spacer />
                  <v-col
                    v-if="event.planning.lesson"
                    class="text-align-right shrink mr-1"
                  >
                    {{ getUsedUnits(event.planning)
                    }}<span v-if="maximumUnit(event.planning.lesson) != '~'"
                      >/{{ maximumUnit(event.planning.lesson) }}</span
                    >
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-btn icon @click="editPlanning(selectedEvent)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon>
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.details"></span>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="secondary" @click="selectedOpen = false"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
        <PlanningEditionPopup
          ref="planningEdition"
          @onSaveClicked="saveClicked"
          @onCloseClicked="retrieveAll"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PlanningEditionPopup from "@/components/Admin/Planning/PlanningEditionPopup";
import SearchBox from "@/components/Admin/SearchBox";

export default {
  components: {
    PlanningEditionPopup,
    SearchBox,
  },
  data: () => ({
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    search: "",
  }),
  created() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }

    if (this.$route.query.focus) {
      this.focus = this.$route.query.focus;
    }
  },
  watch: {
    $route(to) {
      if (to.query.type) {
        this.type = to.query.type;
      }

      if (to.query.focus) {
        this.focus = to.query.focus;
      }
    },
  },
  mounted() {
    this.retrieveAll();
  },
  methods: {
    maximumUnit(lesson) {
      return lesson.maximumUnit ?? "~";
    },
    getEvents() {
      const search = (this.search ?? "").toLowerCase();

      if (this.search) {
        return this.events.filter(
          (element) =>
            element.planning.lesson?.name?.toLowerCase().includes(search) ||
            element.planning.user?.firstName?.toLowerCase().includes(search) ||
            element.planning.user?.lastName?.toLowerCase().includes(search) ||
            element.planning.planning_memberships.find(
              (pm) =>
                pm.membership.client.firstName
                  ?.toLowerCase()
                  .includes(search) ||
                pm.membership.client.lastName?.toLowerCase().includes(search) ||
                pm.membership.client.groups.find((g) =>
                  g.name?.toLowerCase().includes(search)
                )
            )
        );
      }

      return this.events;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.canceled ? `${event.color}77` : event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    //showEvent({ nativeEvent, event }) {
    showEvent({ event }) {
      this.editPlanning(event);
      // const open = () => {
      //   this.selectedEvent = event;
      //   this.selectedElement = nativeEvent.target;
      //   setTimeout(() => (this.selectedOpen = true), 10);
      // };

      // if (this.selectedOpen) {
      //   this.selectedOpen = false;
      //   setTimeout(open, 10);
      // } else {
      //   open();
      // }

      // nativeEvent.stopPropagation();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    saveClicked() {
      this.retrieveEvents();
    },
    newPlanning() {
      this.$refs.planningEdition.open({});
    },
    retrieveEvents() {
      if (this.type && this.focus) {
        const path =
          this.$route.path +
          "?type=" +
          encodeURIComponent(this.type) +
          "&focus=" +
          encodeURIComponent(this.focus);

        if (this.$route.fullPath !== path) this.$router.push(path);
      }

      const start = this.$refs.calendar.lastStart.date;
      const end = this.$refs.calendar.lastEnd.date;

      const startDate = new Date(start);
      startDate.setDate(startDate.getDate() - 7);

      const endDate = new Date(end);
      endDate.setDate(endDate.getDate() + 7);

      // const min = new Date(`${startDate}T00:00:00`);
      // const max = new Date(`${endDate}T23:59:59`);

      const min = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      );
      const max = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        23,
        59,
        59
      );

      // console.log(min + " => " + max);

      this.$services.planning
        .getAll(min, max)
        .then((response) => {
          //console.dir(response.data);
          this.events = response.data.map((planning) => {
            return {
              name:
                (planning.lesson != null
                  ? planning.lesson.name
                  : planning.name) ?? "",
              start: this.$moment(planning.dateBegin).toDate(),
              end: this.$moment(planning.dateEnd).toDate(),
              color:
                planning.lesson != null
                  ? planning.lesson.color
                  : planning.color ?? "#000000",
              id: planning.id,
              timed: true,
              icon: planning.canceled ? "mdi-cancel" : null,
              canceled: planning.canceled,
              planning: planning,
            };
          });
        })
        .catch((error) => {
          this.$helpers.snackbar.handleError(error);
        });
    },
    retrieveAll() {
      this.$refs.calendar.checkChange();
    },
    editPlanning(planning) {
      this.$services.planning
        .get(planning.id)
        .then((response) => {
          this.$refs.planningEdition.open(response.data);
        })
        .catch((error) => {
          this.$helpers.snackbar.handleError(error);
        });
    },
    getUsedUnits(planning) {
      return planning.planning_memberships.reduce(function (a, b) {
        return Number(a) + Number(b.unit ?? 0);
      }, 0);
    },
    swipe(direction) {
      switch (direction) {
        case "left":
          this.next();
          break;
        case "right":
          this.prev();
          break;
      }
    },
  },
};
</script>